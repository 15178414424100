import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { Analysis, RequestWithMeta , CategoryEnum, CategorySectorEnum } from '@/libs/types';

import { apiClient } from '../apiClient';
import { getQueryClient } from '../get-query-client';
import { entitiesInfiniteSelector } from '@/libs/utils';

const PAGE_SIZE = 6;

interface AnalysisProps {
  symbol?: string;
  sector?: CategorySectorEnum;
  category?: CategoryEnum;
}

interface AnalysisQueryParams extends AnalysisProps {
  page?: number;
  perPage?: number;
}

export const ANALYSIS_QUERY_KEY = 'analysis';

export const getAnalysis = async (params: AnalysisQueryParams) => {
  const response = await apiClient.get<RequestWithMeta<Analysis>>('/analysis', { 
    params: {
      ...params,
      perPage: PAGE_SIZE,
    },
   });
   
  return response.data;
};

export const useAnalysisSuspenseInfiniteQuery = (params: AnalysisProps) => {  
  return useSuspenseInfiniteQuery({
    queryKey: [ANALYSIS_QUERY_KEY, params],
    queryFn: ({ pageParam = 1 }) => getAnalysis({ ...params, page: pageParam  }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, page) => {        
      if (page < lastPage?.meta?.lastPage) return page + 1;
    },
    select: entitiesInfiniteSelector,
  });
};

export const prefetchAnalysisSuspenseInfiniteQuery = (params: AnalysisProps) => {  
  const queryClient = getQueryClient()

  queryClient.prefetchInfiniteQuery({
    queryKey: [ANALYSIS_QUERY_KEY, params],
    queryFn: () => getAnalysis(params),
    initialPageParam: 1
  })

  return queryClient;
}