import { useSuspenseQuery } from '@tanstack/react-query';

import { CategoryEnum, CategorySectorEnum, Ticker, Request } from '@/libs/types';
import { entitySelector } from '@/libs/utils';

import { getQueryClient } from '../get-query-client';
import { apiClient } from '../apiClient';

interface TickersProps {
  sector?: CategorySectorEnum;
  category?: CategoryEnum;
}

export const TICKERS_QUERY_KEY = 'tickers';

export const getTickers = async (params?: TickersProps) => {
  const response = await apiClient.get<Request<Ticker[]>>('/tickers', { params });
   
  return response.data;
};

export const useTickersSuspenceQuery = (params?: TickersProps) => {
  return useSuspenseQuery({
    queryKey: [TICKERS_QUERY_KEY, params],
    queryFn: () => getTickers(params),
    select: entitySelector,
  });
};

export const prefetchTickersSuspenseQuery = (params?: TickersProps) => {
  const queryClient = getQueryClient()

  queryClient.prefetchQuery({
    queryKey: [TICKERS_QUERY_KEY, params],
    queryFn: () => getTickers(params),
  })

  return queryClient;
}