import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../apiClient";

type LoginFormValues = {
  email: string;
  password: string;
};

export const useLogin = () => {
  return useMutation({
    mutationKey: ['sighIn'],
    mutationFn: (data: LoginFormValues) => apiClient.post<void>('/v1/login', data)
  });
};
