import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../apiClient";

type RegisterFormValues = {
  email: string;
  password: string;
};

export const useRegister = () => {
  return useMutation({
    mutationKey: ['sighOut'],
    mutationFn: (data: RegisterFormValues) => apiClient.post<void>('/v1/register', data)
  });
};
