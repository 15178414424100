import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../apiClient";
import { Request } from "@/libs/types";

type User = {
  id: number,
  email: string,
  password: false,
  role: true,
  createdAt: true,
  updatedAt: true,
};

export const queryFn = async () => {
  const response = await apiClient.get<Request<User>>('/v1/users/me');
   
  return response.data;
};

export const useUserMe = () => {
  return useQuery({
    queryKey: ['userMe'],
    queryFn,
    retry: false,
  });
};
