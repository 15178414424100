"use client"

import { useState, useMemo, useEffect } from "react"
import { useRouter, useParams } from 'next/navigation';
import { useTranslations } from "next-intl";

import * as PopoverPrimitive from "@radix-ui/react-popover"
import { FixedSizeList as List } from "react-window"
import { Search, X } from "lucide-react"
import { Command as CommandPrimitive } from "cmdk"

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Input } from "@/components/ui/input"
import { Popover, PopoverContent } from "@/components/ui/popover"
import { useTickersSuspenceQuery } from "@/libs/api"

const maxVisibleItems = 6;
const itemSize = 40;

export default function Autocomplete() {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [value, setValue] = useState("")

  const t = useTranslations('Common');

  const params = useParams<{ symbol?: string }>();
  const router = useRouter();

  const clearSelection = () => {
    setValue("")
    setSearch("")
  }

  useEffect(() => {
    if (!params?.symbol) {
      clearSelection();
    } else {
      setValue(params.symbol)
      setSearch(params.symbol)
    }
  }, [params?.symbol]);

  const { data } = useTickersSuspenceQuery()

  const options = useMemo(
    () =>
      data?.entities?.map((ticker) => ({
        value: ticker.symbol,
        label: ticker.symbol,
      })) ?? [],
    [data?.entities]
  )

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(search.toLowerCase())
  )

  const dropdownHeight = Math.min(filteredOptions.length, maxVisibleItems) * itemSize

  return (
    <div className="flex items-center">
      <Popover open={open} onOpenChange={setOpen}>
        <Command>
          <PopoverPrimitive.Anchor asChild>
            <div className="relative w-[300px]">
              <CommandPrimitive.Input
                asChild
                value={search}
                onValueChange={setSearch}
                onKeyDown={(e) => setOpen(e.key !== "Escape")}
                onMouseDown={() => setOpen((open) => !!search || !open)}
                onFocus={() => setOpen(true)}
                onBlur={(e) => {
                  if (!e.relatedTarget?.hasAttribute("cmdk-list")) {
                    setSearch(
                      value
                        ? options.find(
                            (option) => option.value === value
                          )?.label ?? ""
                        : ""
                    )
                  }
                }}
              >
                <Input
                  placeholder={t('searchPlaceholder')}
                  className="w-[290px]"
                />
              </CommandPrimitive.Input>

              {value ? (
                <X
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  size={18}
                  onClick={clearSelection}
                />
              ) : (
                <Search
                  className="absolute right-4 top-1/2 transform -translate-y-1/2"
                  size={18}
                />
              )}
            </div>
          </PopoverPrimitive.Anchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            onOpenAutoFocus={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault()
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              {filteredOptions.length === 0 && search ? (
                <CommandEmpty>No tickers found.</CommandEmpty>
              ) : (
                <CommandGroup>
                  <List
                    height={dropdownHeight}
                    itemCount={filteredOptions.length}
                    itemSize={itemSize}
                    width="100%"
                  >
                    {({ index, style }) => {
                      const option = filteredOptions[index]

                      return (
                        <CommandItem
                          key={option.value}
                          value={option.value}
                          onMouseDown={(e) => e.preventDefault()}
                          onSelect={(currentValue) => {
                            setValue(currentValue === value ? "" : currentValue)
                            setSearch(
                              currentValue === value
                                ? ""
                                : options.find(
                                    (option) => option.value === currentValue
                                  )?.label ?? ""
                            )
                            setOpen(false);

                            if (currentValue) {
                              router.push(`/tickers/${currentValue}`);
                            }
                          }}
                          style={style}
                        >
                          {option.label}
                        </CommandItem>
                      )
                    }}
                  </List>
                </CommandGroup>
              )}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  )
}
